// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kit-comunicazione-pa-js": () => import("./../../../src/pages/kit-comunicazione-pa.js" /* webpackChunkName: "component---src-pages-kit-comunicazione-pa-js" */),
  "component---src-pages-note-legali-js": () => import("./../../../src/pages/note-legali.js" /* webpackChunkName: "component---src-pages-note-legali-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-supporto-js": () => import("./../../../src/pages/supporto.js" /* webpackChunkName: "component---src-pages-supporto-js" */)
}

